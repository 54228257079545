<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_04.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container section__margin-bottom">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <div
            class="d-flex align-self-center justify-center align-center title"
          >
            <img
              src="../../assets/conteudos_luta_continua_title_02.png"
              alt=""
            />
          </div>
        </v-row>
        <v-row
          class="d-flex justify-md-space-around align-center pt-5 pb-5 ovx"
        >
          <v-col
            cols="4"
            md="2"
            class="d-flex justify-center align-center text-center"
          >
            <router-link :to="{ name: 'conteudos_tematicos_luta_continua' }">
              Luta pelo reassentamento
            </router-link>
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="d-flex justify-center align-center text-center"
          >
            <router-link :to="{ name: 'conteudos_tematicos_luta_musica' }">
              Música A LUTA
            </router-link>
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="d-flex justify-center align-center text-center"
          >
            <router-link :to="{ name: 'conteudos_tematicos_luta_crime' }">
              Não prescrição do crime
            </router-link>
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="d-flex justify-center align-center text-center"
          >
            <router-link :to="{ name: 'conteudos_tematicos_luta_reparacao' }">
              Reparação Integral
            </router-link>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  A participação ativa das pessoas submetidas a essas graves
                  violações de direitos humanos na construção e execução dos
                  mecanismos que as atenderão é fundamental para a efetiva
                  concretização da reparação dos danos sofridos pelas pessoas
                  atingidas pelo rompimento da barragem de Fundão.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="Medidas para uma reparação integral"
              title_alignment="center"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Ações no sentido de reparação integral<sup>2</sup> devem
                  contemplar as seguintes medidas:
                </p>

                <div class="d-flex flex-column">
                  <div
                    class="d-flex flex-column align-start justify-center card--box mb-3"
                  >
                    <div class="d-flex justify-start align-center">
                      <img
                        width="20px"
                        src="../../assets/conteudos_luta_continua_circle_title_01.png"
                        alt=""
                      />
                      <p class="ml-2" style="margin-bottom: 0;">
                        <strong>Restituição</strong>
                      </p>
                    </div>
                    <p class="mt-2" style="margin-bottom: 0;">
                      Devolver aos atingidos e atingidas situação próxima a
                      anterior à violação sofrida. Com o rompimento da barragem
                      de Fundão, por exemplo, o direito à moradia adequada foi
                      violado e, portanto, deve ser restituído. Disso,
                      conclui-se pela obrigação da construção coletiva e
                      participativa dos reassentamentos, observando os critérios
                      de habitabilidade de acordo com a realidade de cada núcleo
                      familiar.
                    </p>
                  </div>

                  <div
                    class="d-flex flex-column align-start justify-center card--box mb-3"
                  >
                    <div class="d-flex justify-start align-center">
                      <img
                        width="20px"
                        src="../../assets/conteudos_luta_continua_circle_title_02.png"
                        alt=""
                      />
                      <p class="ml-2" style="margin-bottom: 0;">
                        <strong>Compensação</strong>
                      </p>
                    </div>
                    <p class="mt-2" style="margin-bottom: 0;">
                      Indenizar, em dinheiro, pela perda dos bens materiais e
                      imateriais que não podem ser restituídos. Assim, a perda
                      de uma vida, a perda da relação com o rio e com a
                      comunidade, por exemplo, devem ser indenizadas. Porém, a
                      indenização é insuficiente para reparar integralmente as
                      vítimas de violações de direitos humanos. A compensação
                      compreende ainda a garantia de direitos a que as vítimas
                      não tinham acesso, ou seja, a melhoria nas condições de
                      vida anteriores à violação.
                    </p>
                  </div>

                  <div
                    class="d-flex flex-column align-start justify-center card--box mb-3"
                  >
                    <div class="d-flex justify-start align-center">
                      <img
                        width="20px"
                        src="../../assets/conteudos_luta_continua_circle_title_03.png"
                        alt=""
                      />
                      <p class="ml-2" style="margin-bottom: 0;">
                        <strong>Reabilitação</strong>
                      </p>
                    </div>
                    <p class="mt-2" style="margin-bottom: 0;">
                      Criar condições para a recuperação da saúde física, mental
                      e para a retomada dos modos de vida, prejudicadas pelo
                      rompimento da barragem. Ressalta-se que muitos atingidos e
                      atingidas sofreram — e ainda sofrem — danos à saúde física
                      e psicológica. Além disso, deverá ser garantida a
                      reativação econômica das comunidades atingidas.
                    </p>
                  </div>

                  <div
                    class="d-flex flex-column align-start justify-center card--box mb-3"
                  >
                    <div class="d-flex justify-start align-center">
                      <img
                        width="20px"
                        src="../../assets/conteudos_luta_continua_circle_title_04.png"
                        alt=""
                      />
                      <p class="ml-2" style="margin-bottom: 0;">
                        <strong>Satisfação</strong>
                      </p>
                    </div>
                    <p class="mt-2" style="margin-bottom: 0;">
                      Revelação da verdade, garantia de memória e justiça para o
                      conjunto de atingidos. O objetivo da satisfação é a
                      recuperação da dignidade e reputação das vítimas, bem como
                      valor educativo para que tragédias, como a do rompimento
                      da barragem de Fundão, não venham a se repetir no futuro.
                      As empresas e o Estado precisam reconhecer a própria
                      responsabilidade pelas violações cometidas e pedir
                      desculpas publicamente. As medidas de satisfação incluem
                      ainda a aplicação de penalidades aos responsáveis pelo
                      crime (processo criminal) e a busca por manter viva a
                      memória das vítimas, seu legado e as circunstâncias do
                      caso — um exemplo seria a construção de memoriais.
                    </p>
                  </div>

                  <div
                    class="d-flex flex-column align-start justify-center card--box"
                  >
                    <div class="d-flex justify-start align-center">
                      <img
                        width="20px"
                        src="../../assets/conteudos_luta_continua_circle_title_05.png"
                        alt=""
                      />
                      <p class="ml-2" style="margin-bottom: 0;">
                        <strong>Não repetição</strong>
                      </p>
                    </div>
                    <p class="mt-2" style="margin-bottom: 0;">
                      Apresentar mecanismos e/ou políticas que garantam a não
                      repetição do fato, neste caso, o rompimento da barragem de
                      Fundão. As medidas de não repetição são de
                      responsabilidade das empresas (com a criação e aplicação
                      de políticas de segurança) e do Estado (criação de
                      legislação e fiscalização constante) e compreendem meios
                      para que desastres criminosos como estes não voltem a se
                      repetir.
                    </p>
                  </div>
                </div>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center">
          <v-col class="d-flex justify-center align-center">
            <img
              width="900px"
              src="../../assets/conteudos_luta_continua_grafico.png"
              alt=""
            />
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="justify-center align-center">
          <v-col cols="12" md="4" class="justify-center align-center">
            <div
              class="d-flex flex-column align-center justify-center mb-3 cartao1"
            >
              <div class="d-flex justify-center align-center">
                <img
                  width="20px"
                  src="../../assets/conteudos_luta_continua_circle_title_01.png"
                  alt=""
                />
                <p
                  class="ml-2"
                  style="margin-bottom: 0; color: red; font-size: 20px"
                >
                  <strong>Restituição</strong>
                </p>
              </div>
              <p
                class="mt-2"
                style="margin-bottom: 0; text-align: center; font-size: 16px;"
              >
                É a reposição do bem perdido e a recuperação da condição
                econômica e financeira de antes da tragédia. Ex.:
                reassentamento.
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="justify-center align-center">
            <div
              class="d-flex flex-column align-center justify-center mb-3 cartao2"
            >
              <div class="d-flex justify-center align-center">
                <img
                  width="20px"
                  src="../../assets/conteudos_luta_continua_circle_title_02.png"
                  alt=""
                />
                <p
                  class="ml-2"
                  style="margin-bottom: 0; color:#FAD803; font-size: 20px"
                >
                  <strong>Compensação / Indenização</strong>
                </p>
              </div>
              <p
                class="mt-2"
                style="margin-bottom: 0; text-align: center; font-size: 16px;"
              >
                Melhorias das condições e pagamentos de danos. Longe de serem
                “favores”, estas ações fazem parte do processo de reparação.
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="justify-center align-center">
            <div
              class="d-flex flex-column align-center justify-center mb-3 cartao3"
            >
              <div class="d-flex justify-center align-center">
                <img
                  width="20px"
                  src="../../assets/conteudos_luta_continua_circle_title_03.png"
                  alt=""
                />
                <p
                  class="ml-2"
                  style="margin-bottom: 0; color:#01BFFC; font-size: 20px"
                >
                  <strong>Reabilitação</strong>
                </p>
              </div>
              <p
                class="mt-2"
                style="margin-bottom: 0; text-align: center; font-size: 16px;"
              >
                Corresponde à melhoria das condições de atendimento e apoio,
                inclusive assistência à saúde mental garantida aos atingidos.
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="4" class="justify-center align-center">
            <div
              class="d-flex flex-column align-center justify-center mb-3 cartao4"
            >
              <div class="d-flex justify-center align-center">
                <img
                  width="20px"
                  src="../../assets/conteudos_luta_continua_circle_title_04.png"
                  alt=""
                />
                <p
                  class="ml-2"
                  style="margin-bottom: 0; color:#892CB4; font-size: 20px"
                >
                  <strong>Satisfação</strong>
                </p>
              </div>
              <p
                class="mt-2"
                style="margin-bottom: 0; text-align: center; font-size: 16px;"
              >
                É dever do Estado e dos responsáveis pelo crime prestar
                satisfações públicas pelo ocorrido: pedido de desculpas,
                construção de memoriais e tombamento de bens históricos fazem
                parte deste processo.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="4" class="justify-center align-center">
            <div
              class="d-flex flex-column align-center justify-center mb-3 cartao5"
            >
              <div class="d-flex justify-center align-center">
                <img
                  width="20px"
                  src="../../assets/conteudos_luta_continua_circle_title_05.png"
                  alt=""
                />
                <p
                  class="ml-2"
                  style="margin-bottom: 0; color:#018303; font-size: 20px"
                >
                  <strong>Não repetição</strong>
                </p>
              </div>
              <p
                class="mt-2"
                style="margin-bottom: 0; text-align: center; font-size: 16px;"
              >
                É preciso garantir à sociedade que o mal não se repetirá. O
                rompimento da Barragem de Fundão é uma oportunidade de revisão
                da legislação sobre mineração no Brasil.
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
          <v-col class="d-flex justify-center align-center">
            <div
              class="d-flex justify-center align-center"
              style="border-radius: 8px; background-color: white; width: 300px; height: 100px; padding: 1em;"
            >
              <img src="/logos/logo-caritas-main-preto.png" alt="" />
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  Em síntese, compreende-se que a reparação das inestimáveis
                  perdas e danos causados pelo rompimento da barragem de
                  rejeitos da Samarco (Vale e BHP Billiton) deve ser a mais
                  ampla e participativa possível. O
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >Cadastro</router-link
                  >
                  construído em Mariana visa levantar as perdas e os danos
                  sofridos pelos atingidos e atingidas pela barragem de Fundão,
                  ocorrido no dia 05 de novembro de 2015. Assim, o objetivo
                  principal é contribuir para uma reparação justa. Isso
                  significa dizer que ele fornece informações para as diversas
                  formas de reparação, tendo em vista os dados que levanta, tais
                  como, perdas materiais, atividades econômicas, dano pela perda
                  de bens coletivos, danos imateriais e morais.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="Fundamentação jurídica"
              title_alignment="center"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Dentre as normativas em que se apoia a reparação integral no
                  Brasil, está a Constituição Federal de 1988 (CRFB/88), que
                  traz em seu art. 4º, inciso II, o princípio da prevalência dos
                  direitos humanos, que deverão nortear as relações
                  internacionais do Brasil. Assim como dispõe, em seu art. 5º, §
                  2º, que os direitos expressos na CRFB/88 não excluem outros
                  decorrentes dos tratados internacionais dos quais o Brasil
                  seja parte, chancelando, desta forma, a incorporação e a
                  integração de tratados e convenções internacionais de direitos
                  humanos ao ordenamento jurídico brasileiro. A Emenda
                  Constitucional nº 45 de 2004 acrescentou o § 3º ao art. 5º da
                  CRFB/88, estabelecendo que tratados e convenções
                  internacionais de direitos humanos terão a mesma hierarquia de
                  normas constitucionais quando aprovados por maioria
                  qualificada de três quintos em ambas as casas legislativas
                  federais e, quando não submetidos a este procedimento, por
                  entendimento consolidado da Suprema Corte, terão status de
                  norma supralegal. Para interpretar esse conteúdo dos tratados
                  de direitos humanos, há a jurisprudência das cortes
                  internacionais e diversos documentos elaborados por
                  especialistas e órgãos competentes, todos eles essenciais para
                  que os Estados-partes estejam em acordo com suas
                  responsabilidades convencionais.
                </p>
                <p>
                  Deve-se atentar para instrumentos como a Declaração do Rio
                  sobre Meio Ambiente e Desenvolvimento aprovada por ocasião da
                  Conferência das Nações Unidas sobre o Meio Ambiente e o
                  Desenvolvimento (CNUMAD), realizada no Rio de Janeiro em 1992
                  (Eco 92), que estabeleceu em seu Princípio 10 que os Estados
                  devem proporcionar às pessoas atingidas por danos ambientais
                  acesso efetivo a meios judiciais e administrativos para a
                  compensação e reparação de danos. Mais recentemente, em março
                  de 2018, o Brasil tornou-se signatário do Acordo Regional
                  sobre Acesso à Informação, Participação Pública e Acesso à
                  Justiça em Assuntos Ambientais na América Latina e no Caribe.
                  Trata-se de um tratado regional vinculante firmado no âmbito
                  da Comissão das Nações Unidas para a América Latina e Caribe
                  (CEPAL) que explicita, em seu Artigo 8, os compromissos a
                  serem adotados pelos Estados para garantir o acesso à justiça
                  e participação em assuntos ambientais. De acordo com o
                  referido dispositivo, os Estados-partes deverão adotar
                  mecanismos de reparação que promovam a restituição ao estado
                  anterior ao dano, a restauração, a compensação ou a imposição
                  de sanções econômicas, a satisfação, garantias de não
                  repetição e atenção às pessoas afetadas e instrumentos
                  financeiros para apoiar a reparação.
                </p>
                <p>
                  Verificando ainda outros instrumentos de direito internacional
                  de direitos humanos, de acordo com os parâmetros fixados pelos
                  Princípios Orientadores sobre Empresas e Direitos Humanos das
                  Nações Unidas, é dever dos Estados garantir às vítimas de
                  violações de direitos humanos perpetradas por empresas o
                  acesso às reparações efetivas através de medidas judiciais,
                  administrativas ou legislativas que sejam legítimas,
                  acessíveis, previsíveis, equitativas, transparentes,
                  participativas e dialógicas, que promovam, portanto, um
                  acúmulo de aprendizado contínuo e que evite a repetição das
                  violações e suas consequências danosas.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <div
              style="background-color: white; border-radius: 8px; padding: 1em;"
            >
              <p style="font-size: 16px; margin-bottom: 0;">
                Texto adaptado da Introdução dos Dossiês Familiares produzidos
                no Cadastro realizado pela Cáritas Brasileira Regional Minas
                Gerais no âmbito da Assessoria Técnica aos Atingidos e Atingidas
                pelo Rompimento da Barragem do Fundão em Mariana-MG.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p>
                <sup>1</sup> CLÍNICA de Direitos Humanos da UFMG (CdH-UFMG),
                CONECTAS Direitos Humanos.
                <strong
                  >Transparência, participação, responsabilização e
                  reparação</strong
                >
                uma proposta de reforma de governança para a remediação do Rio
                Doce. 1 ed, abril de 2018. Disponível em:
                <a
                  style="text-decoration: none;"
                  class="pink--text"
                  target="_blank"
                  href="http://www.conectas.org/wp/wp-content/uploads/2018/06/POLICY_PAPER_Rio-Doce.pdf"
                  >http://www.conectas.org/wp/wp-content/uploads/2018/06/POLICY_PAPER_Rio-Doce.pdf</a
                >
                .
              </p>
              <p style="margin-bottom: 0;">
                <sup>2</sup> ALEIXO, Letícia Soares Peixoto.
                <strong
                  >Efetividade dos Direitos Humanos e a necessária elaboração
                  legislativa em matéria de implementação das sentenças
                  interamericanas no Brasil</strong
                >. Dissertação (Mestrado) – Universidade Federal de Minas
                Gerais. Belo Horizonte, 2017. Disponível em:
                <a
                  style="text-decoration: none;"
                  class="pink--text"
                  href="https://goo.gl/662pho"
                  >https://goo.gl/662pho</a
                >
                .
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="12" md="7" class="d-flex justify-center align-center">
            <v-btn :to="{ name: 'conteudos_tematicos' }" color="#EDBD43">
              <strong>Voltar para o início de "Conteúdos Temáticos"</strong>
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "A Luta Continua /",
          href: "conteudos_tematicos_luta_continua",
        },
        {
          text: "Reparação integral",
          href: "conteudos_tematicos_luta_reparacao",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.cartao1 {
  background-color: white;
  border: 1px solid red;
  border-radius: 8px;
  padding: 1em;
  height: 200px;
}

.cartao2 {
  background-color: white;
  border: 1px solid #fad803;
  border-radius: 8px;
  padding: 1em;
  height: 200px;
}

.cartao3 {
  background-color: white;
  border: 1px solid #01bffc;
  border-radius: 8px;
  padding: 1em;
  height: 200px;
}

.cartao4 {
  background-color: white;
  border: 1px solid #892cb4;
  border-radius: 8px;
  padding: 1em;
  height: 200px;
}

.cartao5 {
  background-color: white;
  border: 1px solid #018303;
  border-radius: 8px;
  padding: 1em;
  height: 200px;
}

.card--box {
  padding: 1em;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
}

a {
  color: #edbd43;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #edbd43;
}

.title img {
  width: 40%;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .title img {
    width: 40%;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}

@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    overflow-y: auto;

    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }

  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px #426e51;
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: #426e51;
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
</style>
